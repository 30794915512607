.about-container {
  width: 100% !important;
  background-color: transparent !important;
}

.about-question-container {
  color: rgba(255, 255, 255, 0.8) !important;
  border-top: 1px solid rgba(96, 96, 96, 0.9) !important;
}

.about-answer-container {
  padding-left: 4;
  color: rgba(255, 255, 255, 0.8) !important;
}

.about-header {
  font-size: 1.2rem !important;
  color: rgba(255, 255, 255, 0.9) !important;
  background-color: transparent !important;
}