.home-playlist-container {
  position: relative;
}

.home-playlist-thumbnails-container {
  display: inline-flex;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: 100%;
}

.home-playlist-thumbnails-container::-webkit-scrollbar {
  display: none;
}

.home-playlist-title {
  font-size: 1.4rem !important;
  padding-top: 7px !important;
  margin-left: 10px !important;
  font-weight: 500 !important;
}

.home-playlist-arrow-left {
  cursor: pointer;
  position: absolute;
  top: 60px;
  left: 0px;
  background-color: rgba(62, 61, 61, 0.4);
  height: 40%;
  width: 40px;
  border-radius: 5px;
  z-index: 2;
}

.home-playlist-arrow-left:hover {
  background-color: rgba(62, 61, 61, 0.7);
}

.home-playlist-arrow-icon-left {
  color: rgba(255, 255, 255, 0.9);
  font-size: 40px !important;
  height: 100% !important;
  line-height: 100% !important;
  vertical-align: middle !important;
  margin-left: 8px;
}

.home-playlist-arrow-icon-left:hover {
  color: rgba(255, 255, 255);
}

.home-playlist-arrow-right {
  cursor: pointer;
  position: absolute;
  top: 60px;
  right: 0px;
  background-color: rgba(62, 61, 61, 0.4);
  height: 40%;
  width: 40px;
  border-radius: 5px;
  z-index: 2;
}

.home-playlist-arrow-right:hover {
  background-color: rgba(62, 61, 61, 0.8);
}

.home-playlist-arrow-icon-right {
  color: rgba(255, 255, 255, 0.9);
  font-size: 40px !important;
  height: 100% !important;
  line-height: 100% !important;
  vertical-align: middle !important;
}

.home-playlist-arrow-icon-right:hover {
  color: rgba(255, 255, 255);
}

.home-video-card-container {
  position: relative;
  margin-right: 10px;
  min-width: 25% !important;
  max-width: 25% !important;
}

.home-video-card-title-container {
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: rgba(62, 61, 61, 0.7);
  height: 35px;
  padding-top: 5px;
  color: white;
  width: 100%;
}

.home-video-card-title {
  margin-left: 0.5rem !important;
  margin-right: 0.1rem !important;
  text-align: left;
  font-size: 1em !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media only screen and (max-width: 1100px) {
  .home-video-card-container {
    position: relative;
    margin-left: 10px;
    min-width: 40% !important;
    max-width: 40% !important;
  }
}

@media only screen and (max-width: 800px) {
  .home-video-card-container {
    position: relative;
    margin-left: 10px;
    min-width: 45% !important;
    max-width: 45% !important;
  }
}

@media only screen and (max-width: 600px) {
  .home-video-card-container {
    position: relative;
    margin-left: 10px;
    min-width: 80% !important;
    max-width: 80% !important;
  }
}