.login-left-text-container {
  font-family: "Open Sans", sans-serif !important;
  font-size: 1.5rem;
  margin-left: 2rem;
  font-weight: 100;
}

.login-button-checkbox {
  font-size: 1rem !important;
  color: rgba(174, 174, 174, 0.9) !important;
  text-transform: none !important;
}

.login-button-checkbox > div > label > span > svg {
  fill: white !important;
}

.login-button {
  font-size: 1rem !important;
  border: 1px solid rgba(174, 174, 174, 0.9) !important;
  color: rgba(174, 174, 174, 0.9) !important;
  text-transform: none !important;
}

.login-button-borderless {
  font-size: 1rem !important;
  color: rgba(174, 174, 174, 0.9) !important;
  text-transform: none !important;
}

.login-textfield {
  width: 90%;
  margin-bottom: 0.5rem !important;
}

.login-textfield > label {
  color: rgba(255, 255, 255, 0.9) !important;
}

.login-textfield > div {
  color: rgba(255, 255, 255, 0.9) !important;
}

.login-textfield > div > fieldset {
  border-color: rgba(96, 96, 96, 0.9) !important;
}

@media only screen and (max-width: 1100px) {
  .login-textfield {
    width: 90%;
  }
}

@media only screen and (max-width: 899px) {
  .login-textfield {
    width: 70%;
  }
  
  .login-component-container {
    margin-top: 1.5rem !important;
  }
}

@media only screen and (max-width: 600px) {
  .login-textfield {
    width: 80%;
  }
}