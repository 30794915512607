.home-app-bar {
  width: 100% !important;
}

.appbar-title {
  text-transform: none !important;
  color: rgb(255, 255, 255) !important;
  font-size: 1.3rem !important;
  font-weight: 500 !important;
}

.appbar-logo {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
  margin-left: 1rem;
  font-size: 1.4rem;
}

.custom-menu-item {
  display: flex;
  text-decoration: none;
  margin-right: 1rem;
  color: rgb(255, 255, 255) !important;
  background-color: transparent !important;
  text-transform: none !important;
  font-size: 1.1rem !important;
  font-weight: 300 !important;
  justify-content: center !important;
  align-items: center !important;
}

@media only screen and (max-width: 990px) {
  .appbar-title {
    text-transform: none !important;
    color: rgb(255, 255, 255) !important;
    font-size: 1.1rem !important;
    font-weight: 500 !important;
  }
  
  .appbar-logo {
    height: 9px;
    margin-right: 5px;
  }

  .custom-menu-item {
    display: flex;
    text-decoration: none;
    margin-right: 1rem;
    margin-top: 0.5rem;
    color: rgb(255, 255, 255) !important;
    background-color: transparent !important;
    text-transform: none !important;
    font-size: 1.1rem !important;
    font-weight: 300 !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

.navbar-toggler-icon {
  color: white;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  border: none;
  outline: none;
  box-shadow: none;
}